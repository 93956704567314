@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css");

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.card-img-top {
  width: 100%;
  height: 22vw;
  object-fit: cover;
}
.btn {
  margin-right: 20px;
}

.card--subtitle{
  text-align: center;
  font-size: 12px;
  position: relative; bottom: 5px;
  color: orange;
  letter-spacing: -0.05em;
}
.website {
  font-size: 9px;
  margin: auto;
  position: relative; bottom: 20px;
  text-align: center;
}

.button--links{
  position: relative; bottom: 17px;
  background-color: #ddd;
  border: none;
  color: black;
  padding: 2px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 2px 1px;
  cursor: pointer;
  border-radius: 16px;
}

.button--links:hover {
  background-color: orange;
}

.card--body{
  background-color: #282c34;
}
.card--about{
  text-align: left;
  letter-spacing: -0.05em;
}
.about--section{
  text-align: left;
  font-size: 11px;
  word-wrap: break-word;
  
}
.card--intrest{
  text-align: left;
  letter-spacing: -0.05em;
}

.card--title{
  letter-spacing: -0.05em;
}

.footer--links > a {
  padding: 2px 10px;
}

.interest--section{
  text-align: left;
  font-size: 11px;
  word-wrap: break-word;
}